import React, { useState } from 'react';
import InViewAnimateGrow from '../transitions/InViewAnimateGrow';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  IoIosArrowForward,
  IoIosArrowBack,
} from "react-icons/io";

const Testimonial = () => {
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: true,
    slidesToScroll: 1,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    centerPadding: "22rem",
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 770,
        settings: {
          arrows: true,
          slidesToScroll: 1,
          infinite: true,
          slidesToShow: 1,
          centerPadding: "0.3rem",
          swipeToSlide: true,
        },
      },
    ],
  };
  return (
    <div className='w-full px-5' >
      <h2 className='text-center' >Our customers are our biggest fans</h2>
      <p className='mb-8 text-center md:px-20' >
        We don't like to brag, but we don't mind letting our customers do it for us. 
        Here are a few 
        nice things folks have said about our services over the years.
      </p>
      <div className='relative bg-white md:w-full' >
      <button
        className="p-1 bg-white rounded-full top-[50%] text-3xl shadow-sm 
        absolute left-0 z-10 text-primary hidden md:block"
        onClick={sliderRef?.slickPrev}
      >
        <IoIosArrowBack />
      </button>
      <button
        className="p-1 bg-white rounded-full top-[50%] text-3xl shadow-sm 
        absolute right-0 z-10 text-primary hidden md:block"
        onClick={sliderRef?.slickNext}
      >
        <IoIosArrowForward />
      </button>
        <Slider ref={setSliderRef} {...sliderSettings}>
          <div className='bg-white' >
            <div 
              className=' bg-white border md:border-none md:shadow-2xl w-full px-4 md:px-20 
              py-8 md:py-10 text-center md:text-justify md:w-[760px]' 
            >
              <InViewAnimateGrow>
                <p className='mb-4 text-slate-500' >
                  I am delighted to have joined Amby Savings and Loan a year ago! When I encountered 
                  an accommodation issue, I applied for a soft loan and was granted the request without delay. 
                  I am incredibly pleased to be associated with Amby Savings and Loan, and cannot express 
                  my gratitude enough for their swift and helpful response.
                </p>
                <h3 className='text-primary' >Ejeta Ovoke Peter</h3>
              </InViewAnimateGrow>
            </div>
          </div>
          <div className='bg-white' >
            <div 
              className='bg-white border md:border-none md:shadow-2xl w-full px-4 md:px-20 
              py-8 md:py-10 text-center md:text-justify md:w-[760px]' 
            >
              <InViewAnimateGrow>
                <p className='mb-4 text-slate-500' >
                  I have had the pleasure of being both a customer and a staff member of AMBY 
                  SAVINGS AND LOAN, and I am highly impressed with the level of service they have 
                  provided. I have no doubt that they are well-positioned to become a major player 
                  in the finance community, 
                  and I am confident that they will maximize the opportunities available to them.
                </p>
              </InViewAnimateGrow>
            </div>
          </div>
          <div className='bg-white' >
            <div 
              className=' bg-white border md:border-none md:shadow-2xl w-full px-4 md:px-20 
              py-8 md:py-10 text-center md:text-justify md:w-[760px]' 
            >
              <InViewAnimateGrow>
                <p className='mb-4 text-slate-500' >
                  Ambi Savings And Loan is not only unique in the arena of loan facilitation.  
                  The Company is gifted with skilled, compassionate, responsive, abled, educative
                   and dynamic team.
                  My acquintal with the company has lifted up my business from the dungeon of 
                  financial quest.
                </p>
                <h3 className='text-primary' >
                  Mr. Ekundayo, Olaoluwa Samuel, MD/CEO, Hotel du Conceptual & Company.
                </h3>
              </InViewAnimateGrow>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default Testimonial