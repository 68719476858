import React from 'react';
import Logo from "../assets/amby-png.png";

const Footer = () => {
  return (
    <div className='w-full h-[48px] px-5 md:h-[101px] md:px-16 flex justify-between items-center
    flex-col md:flex-row' >
      <a href="#home">
        <img src={Logo} alt="logo" className='cursor-pointer w-[140px] h-[72px] 
        md:w-[262px] md:h-[102px] -ml-3 md:ml-0' />
      </a>
      <small className='pb-4 text-sm md:pb-0' >
        &copy; Website developed by 
        <a 
          href="https://optisoft.ng" 
          className='text-sm font-semibold text-primary' 
          target="_blank"
          rel="noreferrer"
        >
          {" "}Optisoft
        </a>
      </small>
    </div>
  )
}

export default Footer