import React from 'react';
import InViewAnimateTop from '../transitions/InViewAnimateTop';

const Extra = () => {
  return (
    <div className='w-full flex justify-center px-5 md:px-44' >
      <div className='grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-14' >
        <InViewAnimateTop>
          <div>
            <h2 className='text-primary text-center md:text-left'>Our Vision</h2>
            <p className='text-center md:text-left'>
              To become a major Nigerian commercial bank by providing excellent financial services and 
              ensuring optimal client satisfaction.
              </p>
          </div>
        </InViewAnimateTop>
        <InViewAnimateTop>
          <div>
            <h2 className='text-primary text-center md:text-left'>Our Mission</h2>
            <p className='text-center md:text-left'>
              Committed to providing financial resources and services to Nigerians
            </p>
          </div>
        </InViewAnimateTop>
      </div>
    </div>
  )
}

export default Extra