import React from 'react';
import { BsQuestion } from 'react-icons/bs';

const faqs = [
  {
    question: "How do I apply for loan in your company?",
    answer: `To access our loan service, you must first open an account with us. Once you 
    have done so, you will be eligible to apply for a loan with us.`
  },
]

const FAQ = () => {
  return (
    <div>
      <div className='bg-[#1b2a4e] relative w-full py-20' >
        <h2 className='my-8 font-bold text-center text-white md:my-14' >FAQ</h2>
        <div className='grid grid-cols-1 gap-10 px-5 md:grid-cols-2 md:px-20' >
          {
            faqs.map((item, id) => (
              <div className='flex items-start gap-x-4 md' key={id} >
                  <div className='p-1 bg-green-600 rounded-full ' >
                    <BsQuestion className='text-xl text-white ' />
                  </div>
                  <div>
                    <h2 className='mb-5 text-white' >{item.question} </h2>
                    <p className='text-white' >{item.answer}</p>
                  </div>
                </div>
            ))
          }
        </div>
        <div className='absolute w-full h-14 md:h-20 rounded-b-[100%] 
        top-0 bg-white' >

        </div>
        <div className='absolute w-full h-14 md:h-20 rounded-b-[100%] -bottom-14 
        md:-bottom-20 bg-[#1b2a4e]' >

        </div>
      </div>
    </div>
  )
}

export default FAQ