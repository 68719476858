import { GiPayMoney, GiPiggyBank } from 'react-icons/gi';
import { BsCash } from 'react-icons/bs';

export const features = [
  {
    title: "Loan Services",
    detail: `Our loan process is designed to be straightforward and efficient, 
    ensuring that our clients have access to the funds they need in a time-efficient manner.</br>
    We strive to provide a secure and reliable loan experience and are committed to delivering loan services 
    that meet the needs of our clients and provide them with the financial assistance they require.
    `,
    icon: <BsCash />
  }, {
    title: "Secure Savings",
    detail: `At Amby Microfinance, we accept savings as a viable option for our clients.</br> 
    We recognize the value of saving and are confident that our savings options offer 
    a secure and reliable way for our clients to reach their 
    financial goals`,
    icon: <GiPiggyBank />
  }, {
    title: "Reliable fixed deposits",
    detail: `We offer fixed deposits as an excellent option for clients who wish to save 
    their money in a secure and reliable way.</br> Our fixed deposits have flexible repayment 
    options and competitive rates.`,
    icon: <GiPayMoney />
  }
]