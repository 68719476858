import React from 'react';
import Team from '../../assets/about.jpg';
import { BsCheck2 } from 'react-icons/bs';

const offers = [
  "Business Loan",
  "Project Financing",
  "Hire Purchases",
  "Personal and Group Loans",
	"Personal Saving"
]


const AboutUs = () => {
  return (
    <div className='w-full flex justify-center px-5 md:px-16' >
      <div className='flex flex-col-reverse md:grid md:grid-cols-2 gap-x-10 gap-y-14' >
      <div>
          <h2 className='text-primary' >About us.</h2>
          <p>
            Amby Microfinance Bank is dedicated to providing top-notch financial services to all our 
            clients. We provide comprehensive financial 
            solutions, tailored to meet the needs of each individual customer.<br/> Our wide range of 
            services includes money lending, savings, project financing, and fixed deposits. 
            <br/>Amby is committed to providing the highest quality of service, with a focus on customer 
            satisfaction. Get access to reliable financial solutions with Amby
          </p>
        </div>
        <div className='flex justify-center'>
          <img src={Team} alt="team" className='md:w-[482px] md:h-[362px] w-[308px] h-[212px]' />
        </div>
      </div>
    </div>
  )
}

export default AboutUs;