import React from 'react';
import Team from '../../assets/offer.webp';
import { BsCheck2 } from 'react-icons/bs';
import { Animator, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";

const offers = [
  "Business Loan",
  "Project Financing",
  "Hire Purchases",
  "Personal and Group Loans",
	"Personal Saving"
]


const Service = () => {
  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  return (
    <div>
      <div className='w-full flex justify-center px-5 md:px-16' >
        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-14' >
          <div className='flex justify-center'>
            <img src={Team} alt="team" className='md:w-[482px] md:h-[362px] w-[308px] h-[212px]' />
          </div>
          <div>
            <h2 className='text-primary' >What we offer.</h2>
            <p>
              At Amby Microfinance, we believe everyone should have access to banking services regardless of their locations hence our provision of both office and home services that allow our clients to bank at their own convenience. Our services are designed to make banking simpler and more accessible for everyone, and include but aren’t limited to
            </p>
            <ul className='list-none grid grid-cols-1 gap-10 md:grid-cols-2 mt-8 md:pr-4' >
              {
                offers.map((item, id) => (
                  <li className='flex gap-x-4 items-center' key={id} >
                    <div className=' p-1 bg-yellow-500 bg-opacity-50 rounded-full' >
                      <BsCheck2 className='text-primary text-xl font-bold' />
                    </div>
                    <p>{item}</p>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service